.epar_national_map {
    &_form_category {
        font-weight: 700;
        font-size: 16px;
    }

    .ant-form-item-label {
        label {
            font-weight: 400;
        }
    }

    &_submit-button {
        background-color: $eparSelecetdItemBackgroundColor;
        color: white;
        margin-left: 10px;
        border: none;
    }

    &_label {
        padding-right: 20px;
    }

    &_row {
        margin-block: 10px;
    }

    &_radio-button {
        font-weight: 400;
    }

    .ant-radio-button-wrapper-checked {
        background-color: $eparSelecetdItemBackgroundColor;
        color: white;
    }

    .ant-spin-container {
        ul {
            padding: 0px;
            margin: 0px;
        }
    }

    &_detail-view {
        &_row {
            padding-block: 10px;
        }

        .detail-title {
            padding-top: 20px;
        }

        .detail-properties {
            text-align: right;
            padding-right: 10px;
        }
    }
}